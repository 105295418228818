export interface ISpecificationCategoryModelConditionValueMap {
  useIfSpecificationEntitiesIds: string[];
  useIfNotSpecificationEntitiesIds: string[];
}

export interface ISpecificationCategoryConditionsTextMap {
  isRequired: boolean;
  defaultValue?: string;
}

export enum SpecificationCategoryType {
  specificationCategoryValue = 'specificationCategoryValue',
  specificationCategoryText = 'specificationCategoryText',
}
