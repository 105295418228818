import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Grid, Link, Menu, MenuItem, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { Fragment, useContext, useEffect } from 'react';

import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { TaskManagerConnectionState } from '../../../../../../../service/taskManager/ITaskManager';
import { Colors } from '../../../../../../design/color/Colors';
import { ConfirmModal } from '../../../../../../design/modal/confirm/ConfirmModal';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import {
  JiraApplicationFieldData,
  JiraApplicationFieldDataValueType,
} from '../../../../../../../service/taskManager/IJiraTaskManager';
import { IJiraIssueFieldsData } from '../../../../../../../service/application/jira/IJiraIssueFieldsData';
import { ContainerContext } from '../../../../../../../common/container/ContainerContext';
import { AcceptButton } from './ApplicationInfoDetailsForm';
import { ConfigManager } from '../../../../../../../application/config/ConfigManager';
import { generateName } from '../report/ApplicationInfoReport';
import { ApplicationStatuses, Version } from '../../store/ApplicationDetailUI';
import { useI18n } from '../../../../../../../application/language/useI18n';

export interface IApplicationInfoDetailsViewProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export interface IFieldData {
  name: string;
  value: string[];
  integrationType: string;
}

const getFieldsData = (
  fieldsData: JiraApplicationFieldData[] | undefined,
  jiraFields: IJiraIssueFieldsData[] | undefined,
): IFieldData[] | undefined => {
  let mappedFields;

  const typeMapping = {
    noAction: 'Не использовать',
    defaultFieldBehavior: 'По умолчанию',
    requirementShortName: 'Название требования',
  };
  if (fieldsData) {
    mappedFields = fieldsData.map((item) => {
      if (item.fieldValueSettings.type === JiraApplicationFieldDataValueType.defaultFieldBehavior) {
        const fieldName = jiraFields?.find((fieldItem) => fieldItem.key === item.fieldKey)?.name;
        let fieldValues;

        if (typeof item?.fieldValueSettings?.settings?.value === 'string') {
          fieldValues = item?.fieldValueSettings?.settings?.value;
        }
        if (Array.isArray(item?.fieldValueSettings?.settings?.value)) {
          fieldValues = item?.fieldValueSettings?.settings?.value.join(', ');
        }
        if (!item?.fieldValueSettings?.settings?.value) {
          fieldValues = 'Пустое значение';
        }

        return {
          name: fieldName,
          value: fieldValues,
          integrationType: typeMapping[item.fieldValueSettings.type],
        };
      }

      if (item.fieldValueSettings.type === JiraApplicationFieldDataValueType.requirementShortName) {
        const fieldName = jiraFields?.find((fieldItem) => fieldItem.key === item.fieldKey)?.name;

        return {
          name: fieldName,
          value: null,
          integrationType: typeMapping[item.fieldValueSettings.type],
        };
      }

      const fieldName = jiraFields?.find((fieldItem) => fieldItem.key === item.fieldKey)?.name;

      return {
        name: fieldName,
        value: null,
        integrationType: typeMapping[item.fieldValueSettings.type],
      };
    });

    return mappedFields;
  }

  return undefined;
};

const CustomFields = observer(({ domain }: IApplicationInfoDetailsViewProperties) => {
  const fieldsData = getFieldsData(
    domain.ui.editJiraTaskManager.entity.settings?.value?.fields,
    domain.ui.jiraIssuesFields.value.fields,
  );

  return (
    <Grid item>
      {fieldsData?.map((field) => (
        <Typography variant="body1">
          Поле "{field.name}", тип интеграции "{field.integrationType}"{field.value && `, значения "${field.value}"`}
        </Typography>
      ))}
    </Grid>
  );
});

export const QuestionnaireVersion = ({ domain }: IApplicationInfoDetailsViewProperties) => {
  return (<></>);
}

export const ApplicationInfoDetailsView = observer(({ domain }: IApplicationInfoDetailsViewProperties) => {
  const { translate } = useI18n()
  const classes = useStyles();
  const isConnectedJira = domain.ui.editJiraTaskManager.entity.connectionState === TaskManagerConnectionState.connected;
  const jiraProjectId = domain.ui.editJiraTaskManager.entity.settings?.value?.projectId;
  const jiraProject = domain.ui.jiraProjects.value.find((item) => item.id === jiraProjectId);
  const jiraEntity = domain.ui.editJiraTaskManager.entity;
  const currentJiraConnectionData = domain.ui.taskManagerServers.list.find(
    (item) => item.id === domain.ui.editJiraTaskManager.entity.serverId,
  );
  const isSupportAcceptApplication = ConfigManager.getConfig().ui.application.acceptWithVersion;
  const deleteConfirmation = () => (
    <ConfirmModal
      title="Удалить систему?"
      text={`Вы уверены, что хотите удалить систему ${domain.ui.application.entity.name}?`}
      onCancel={async () => {
        domain.layoutDomain.modalWindow.closeModalWindow();
      }}
      onOk={async () => {
        domain.layoutDomain.modalWindow.closeModalWindow();
        domain.deleteApplication();
      }}
    />
  );

  const onTryToDelete = () => {
    domain.layoutDomain.modalWindow.showModalWindow(deleteConfirmation);
  };

  const container = useContext(ContainerContext);
  const ViewModeExtended = container.get(ViewModeLists);
  const QuestionnaireVersionExtended = container.get(QuestionnaireVersion);
  const extendedGenerateName = container.get(generateName);

  const reportNameMajor = `_версия ${domain.ui.getVersionWithStatus({ versionType: Version.major })}`;

  useEffect(() => {
    if (domain.ui.editJiraTaskManager.entity.settings?.value?.projectId) {
      domain.loadJiraProjects();
      domain.loadJiraIssuesFields();
    }
  }, [
    domain.ui.editJiraTaskManager.entity.settings?.value?.projectId,
    domain.ui.editJiraTaskManager.entity.serverId,
    domain,
  ]);

  return (
    <Paper className={classes.root} elevation={3}>
      <QuestionnaireVersionExtended domain={domain} />
      <Grid container direction={'column'} spacing={4} xs={8}>
        {domain.ui.editPerformerTypes.list.length > 1 && (
          <Grid item key="title">
            <Typography variant="h5">{translate('projectPage.typeOfExecutors')}:</Typography>
            <Typography variant="body1">{domain.ui.applicationPerformerType.entity.name}</Typography>
          </Grid>
        )}

        <ViewModeExtended domain={domain} />
        {(
          domain.ui.applicationSpecificationsCategories.list.length === 0
          && domain.ui.application.entity.specificationsTextFields?.fields.length === 0
        )
          && (
            <Grid item key="empty-specifications">
              <Typography variant="h5">{translate('projectPage.noCharacteristics')}</Typography>
            </Grid>
          )}
        {domain.ui.isSupportJira.value && (
          <Grid item key="jira">
            <Grid container spacing={2} direction={'row'}>
              <Grid item>
                <Typography variant="h5">Jira</Typography>
              </Grid>
              <Grid item className={classes.centred}>
                {isConnectedJira && <CloudDoneIcon style={{ color: Colors.positivePassive }} />}
                {!isConnectedJira && <CloudOffIcon style={{ color: Colors.negativePassive }} />}
              </Grid>
            </Grid>
            {isConnectedJira && currentJiraConnectionData && (
              <Grid container direction={'column'}>
                <Grid item>
                  <Typography variant="body1" display="inline" style={{ textDecoration: 'underline' }}>
                    Хост:
                  </Typography>
                  &nbsp;
                  <Typography variant="body1" display="inline">
                    <Link target="_blank" href={currentJiraConnectionData.credentials?.value.host}>
                      {currentJiraConnectionData.credentials?.value.host}
                    </Link>
                  </Typography>
                </Grid>
                {jiraProject?.name && (
                  <Grid item>
                    <Typography variant="body1" display="inline" style={{ textDecoration: 'underline' }}>
                      Проект jira:
                    </Typography>
                    &nbsp;
                    <Typography variant="body1" display="inline">
                      {jiraProject?.name}
                    </Typography>
                  </Grid>
                )}
                {jiraEntity.settings?.value?.issue?.type?.name && (
                  <Grid item>
                    <Typography variant="body1" display="inline" style={{ textDecoration: 'underline' }}>
                      Тип задачи:
                    </Typography>
                    &nbsp;
                    <Typography variant="body1" display="inline">
                      {jiraEntity.settings?.value?.issue?.type?.name}
                    </Typography>
                  </Grid>
                )}
                {!!jiraEntity.settings?.value?.customLabels?.length && (
                  <Grid item>
                    <Typography variant="body1" display="inline" style={{ textDecoration: 'underline' }}>
                      Метки:
                    </Typography>
                    &nbsp;
                    <Typography variant="body1" display="inline">
                      {jiraEntity.settings?.value?.customLabels?.join(', ')}
                    </Typography>
                  </Grid>
                )}
                {!!jiraEntity.settings?.value?.fields?.length && (
                  <Grid item>
                    <Typography variant="body1" display="inline" style={{ textDecoration: 'underline' }}>
                      {translate('phrases.jiraFields')}:
                    </Typography>
                    <CustomFields domain={domain} />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
        <Grid item key="editButtons">
          <Grid container direction={'row'} spacing={2}>
            {domain.ui.isCanUpdate.value === true && (
              <Fragment>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={domain.onClickEditApplication}
                    color="secondary"
                    size="large"
                    startIcon={<EditIcon />}
                  >
                    {translate('phrases.edit')}
                  </Button>
                </Grid>
              </Fragment>
            )}
            {domain.ui.isCanDelete.value === true && (
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    onTryToDelete();
                  }}
                  color="secondary"
                  size="large"
                  startIcon={<EditIcon />}
                  style={{ backgroundColor: Colors.negativePassive }}
                >
                  {translate('phrases.delete')}
                </Button>
              </Grid>
            )}
            <Fragment>
              <Grid item>
                <PopupState variant="popover" popupId="reportsDownloadMenu">
                  {(popupState) => (
                    <React.Fragment>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<SaveIcon />}
                        {...bindTrigger(popupState)}
                      >
                        {translate('projectPage.downloadAsProfile')}
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            domain.downloadApplicationQuestionnaire({
                              format: 'docx',
                              reportName: `${extendedGenerateName({
                                domain,
                                reportName: `Анкета ИБ${reportNameMajor}`,
                              })}`,
                            });
                            popupState.close();
                          }}
                        >
                          Docx формат
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            domain.downloadApplicationQuestionnaire({
                              format: 'pdf',
                              reportName: `${extendedGenerateName({
                                domain,
                                reportName: `Анкета ИБ${reportNameMajor}`,
                              })}`,
                            });
                            popupState.close();
                          }}
                        >
                          Pdf формат
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Grid>
            </Fragment>
            {isSupportAcceptApplication && <AcceptButton domain={domain} />}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});

export const ViewModeLists = observer(({ domain }: { domain: ApplicationDetailDomain }) => (
  <>
    {/* {domain.ui.application.entity.customInformation?.fields.map((info) => (  //SDA-1930  удалить после проверки и принятия задачи
      <Grid style={{ marginBottom: 10 }} item>
        <Typography variant="h5">{info.fieldName}: </Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
          {info.value}
        </Typography>
      </Grid>
    ))} */}
    {domain.ui.application.entity.specificationsTextFields?.fields.map(textFieldData => {
      const category = domain.ui.editSpecificationsCategories.list.find(category => category.id === textFieldData.specificationId)

      return (
        <Grid style={{ marginBottom: 10 }} item>
          <Typography variant="h5">{category?.name || ''}: </Typography>
          <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
            {textFieldData.value}
          </Typography>
        </Grid>)
    })}

    {domain.ui.applicationSpecificationsCategories.list.map((category) => (
      <Grid item key={category.id}>
        <Typography variant="h5">{category.name}:</Typography>
        <Typography style={{ whiteSpace: 'pre-wrap' }} variant="body1">
          {domain.ui
            .specificationsByCategoryId(category.id)
            .map((specification) => specification.name)
            .join('\n')}
        </Typography>
      </Grid>
    ))}
  </>
));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: '20px',
  },
  centred: {
    display: 'flex',
    alignItems: 'center',
  },
}));
