import { Close } from '@mui/icons-material';
import { Grid, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { FormAddButton } from '../../../form/FormActions';

export interface IFormModalComponentProps {
  modalTitle?: string,
  onSave: () => void,
  onCancel: () => void,
  domain: any,
  children: any,
  dataCy?: string,
  buttonText?: string
}

export const FormModalComponent = observer((props: IFormModalComponentProps) => {
  const {
    domain,
    modalTitle = '',
    onSave,
    onCancel,
    children,
    buttonText = 'СОХРАНИТЬ'
  } = props
  const classes = useStyles();

  useEffect(() => {
    domain.layoutDomain.setPageTitle(domain.ui.model.entity.id ? `Редактировать` : `Добавить`);
    return () => {
      domain.ui.validationErrors.setList([])
    };
  }, [domain]);

  return (
    <Grid container className={classes.formModal}>
      <Grid className={classes.formModalHeader}>
        <Grid>
          {modalTitle}
        </Grid>
        <Grid className={classes.formModalHeaderCloseButton}>
          <IconButton
            data-cy={`close-modal`}
            color="secondary"
            aria-label="Close"
            size="small"
            onClick={() => {
              onCancel()
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Grid className={classes.formModalBody}>
        {children}
      </Grid>
      <Grid className={classes.formModalButtonsBlock}>
        <Grid className={classes.formModalButtons}>
          <FormAddButton onSave={onSave} buttonText={buttonText} />
        </Grid>
      </Grid>
    </Grid>
  );
});


const useStyles = makeStyles((theme: Theme) => ({
  formModal: {
    maxWidth: 700,
    minHeight: '500px',
    maxHeight: '80%',
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  formModalHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '100%',
    marginBottom: '12px',
    fontWeight: '600'
  },
  formModalHeaderCloseButton: {
    position: 'absolute',
    right: 24,
  },
  formModalBody: {
    overflowY: 'scroll',
    height: '90%',
    maxHeight: '700px',
    width: '100%',
    paddingBottom: '32px'
  },
  formModalButtonsBlock: {
    width: 'calc(100% + 48px)',
    borderTop: '1px solid #FFFFFF1A',
    marginLeft: -24,
    marginRight: -24,
    padding: '12px 24px',
  },
  formModalButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
