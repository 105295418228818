import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColumnVisibilityModel, GridColumns, GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import React from 'react';

import { ConfigManager } from '../../../../application/config/ConfigManager';
import { ILocalization, ILocalizationToken } from '../../../../application/language/ILocalization';
import { inject } from '../../../../common/container/inject';
import { IValidationErrorData } from '../../../../common/error/IValidationErrorData';
import { injectEntity } from '../../../../common/store/base/injectEntity';
import { injectEntityList } from '../../../../common/store/base/injectEntityList';
import { injectPrimitive } from '../../../../common/store/base/injectPrimitive';
import { IServiceModel } from '../../../../service/common/model/IServiceModel';
import { ISearchRequestData } from '../../../../service/common/search/ISearchRequestData';
import { ISortRule } from '../../../../service/common/search/IServiceModelSort';
import { QuickSearchToolbar } from '../parts/appBar/StickyBar';
import { DataTableRowsUI } from '../parts/rows/DataTableRowsUI';
import { IDataTableRowsUI } from '../parts/rows/IDataTableRowsUI';
import { DataTableFormViewMode } from './DataTableFormViewMode';
import { DataTableOptions } from './DataTableOptions';
import { IActionColumnSettings } from './IActionColumnSettings';
import { IDataTableDomain } from './IDataTableDomain';
import { IDataTableSupportedFeatures } from './IDataTableSupportedFeatures';
import { IDataTableUI } from './IDataTableUI';

export class DefaultSearchRequest implements ISearchRequestData {
  offset = 0;
  limit = 1000000;
}

export class DefaultTableSlots {
  Toolbar = QuickSearchToolbar;
}
export class DataTableUI<
  ServiceModelType extends IServiceModel = IServiceModel,
  SearchRequestType extends ISearchRequestData = ISearchRequestData,
> implements IDataTableUI<ServiceModelType, SearchRequestType> {
  public rows: IDataTableRowsUI<ServiceModelType, SearchRequestType>;

  constructor(
    public domain: IDataTableDomain<ServiceModelType, SearchRequestType>,
    rows: IDataTableRowsUI<ServiceModelType, SearchRequestType> | null,
    public columns = injectPrimitive<GridColumns>([]),
    public rowsCounter = injectPrimitive<any>(0),
    public tableOptions = new DataTableOptions(),
    public tableSlots = injectEntity(new DefaultTableSlots()),
    public isCanEdit = injectPrimitive<boolean | null>(false),
    public isCanDelete = injectPrimitive<boolean | null>(false),
    public isCanCreate = injectPrimitive<boolean | null>(false),
    public isLoading = injectPrimitive<boolean>(false),
    public validationErrors = injectEntityList<IValidationErrorData>([]),
    public rules = injectEntityList<ISortRule>([]),
    public isShown = injectPrimitive<boolean>(false),
    public filterType = injectPrimitive<string | null>(null),
    public toolBarHeight = injectPrimitive<number | null>(null),
    public actionColumnSettings = injectPrimitive<IActionColumnSettings>({
      isShow: true,
      iconDisableHandler: () => {
        return !this.isCanEdit.value && !this.isCanDelete.value;
      },
    }),
    public tableRootPaperElevation = injectPrimitive(3),
    public detailId = injectPrimitive(''),
    public detailURL = injectPrimitive(''),
    public domainID = injectPrimitive(''),
    public tableTitle = injectPrimitive(''),
    public tableSubtitle = injectPrimitive(''),
    public tableAddButtonName = injectPrimitive(''),
    public noRowsLabel = injectPrimitive(''),
    public filterModel = injectEntityList<GridFilterModel>([]),
    public visibilityModel = injectPrimitive<GridColumnVisibilityModel>({}),
    public isShowResetFilters = injectPrimitive<boolean>(false),
    public isDefaultSortModel = injectPrimitive<boolean>(false),
    public languagePackage = inject<ILocalization>(ILocalizationToken),
    public formMode = injectPrimitive<DataTableFormViewMode>(DataTableFormViewMode.modalWindow),
    public features = injectPrimitive<IDataTableSupportedFeatures>({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanRedirectDetail: false,
    }),
    public i18n: ILocalization = inject<ILocalization>(ILocalizationToken),

  ) {
    this.rows = rows || new DataTableRowsUI<ServiceModelType, SearchRequestType>();
  }

  setDomainId(): string {
    return '';
  }
  async getColumns(): Promise<GridColumns> {
    return [];
  }

  onSortModelChange = (model: GridSortModel) => {
    const serviceSortModel = this.gridSortModelToServiceSortModel(model);
    this.rules?.setList(serviceSortModel);
  };

  private gridSortModelToServiceSortModel(gridSortModels: GridSortModel): any[] {
    const gridModels = gridSortModels.map((gridSortModel) => {
      const curSortModel = {
        field: gridSortModel.field,
        sort: gridSortModel.sort,
      };
      return curSortModel;
    });
    return gridModels;
  }

  public async renderTable() {
    await this.renderColumns();
  }

  private async renderColumns() {
    const domainId = this.setDomainId();
    const tableColumns = await this.getColumns();
    const actions = this.actionColumnSettings.value.isShow
      ? [
        this.actionColumnSettings.value.actionItem || {
          field: 'action',
          headerName: 'Действия',
          flex: 0.2,
          headerAlign: 'right',
          sortable: false,
          disableColumnMenu: true,
          align: 'right',
          renderCell: (row: any) => {
            return (
              <IconButton
                // disabled={(() => {
                //   if (this.actionColumnSettings.value?.iconDisableHandler) {
                //     return !!this.actionColumnSettings.value.iconDisableHandler(row);
                //   }
                //   return true;
                // })()}
                data-cy={`actions-button-for-${row.row.name}`}
                onClick={(event) => this.domain.contextMenuDomain.ui.showContextMenu(row.row, event)}
              >
                <MoreVert />
              </IconButton>
            );
          },
          renderHeader: () => {
            return <div />;
          },
        },
      ]
      : [];

    const emptyFirstColumn = {
      headerName: '',
      field: '',
      flex: 0.1,
      sortable: false,
      disableColumnMenu: true,
    }
    let finalColumns = [emptyFirstColumn, ...tableColumns, ...actions];

    // @TODO переметить в плагин ALARM!!!!
    if (ConfigManager.getConfig().extensions.include.includes('gpb')) {
      finalColumns = finalColumns
        .filter((column) => column.headerName !== 'Автор')
        .filter((column) => column.headerName !== 'Тип исполнителей');
    }

    this.columns.setValue(finalColumns);
    this.domainID.setValue(domainId);
  }
}
