import { FormControl, FormHelperText, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { ICommonProperties } from '../../../common/properties/ICommonProperties';

export interface IFormTextAreaProperties extends ICommonProperties {
  label: string;
  onChange: (newValue: string) => void;
  value?: string;
  errorMessage?: string | null;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  rows?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  dataCy?: string;
  paddingSizing?: number;
  size?: "medium" | "small" | undefined,
  autoRows?: boolean,
}

export const FormTextArea = observer(
  ({
    label,
    onChange,
    value = '',
    errorMessage = null,
    disabled = false,
    required = false,
    helperText = undefined,
    rows = '4',
    variant = 'outlined',
    dataCy,
    paddingSizing = 2,
    size = 'medium',
    autoRows = false
  }: IFormTextAreaProperties) => {
    const classes = useStyles();
    const onTextAreaChange = (event: any) => {
      onChange(event.target.value);
    };

    return (
      <FormControl fullWidth sx={{ padding: paddingSizing }} variant="outlined">
        <TextField
          error={!!errorMessage}
          helperText={errorMessage || null}
          onChange={onTextAreaChange}
          color="secondary"
          label={label}
          multiline
          rows={autoRows ? undefined : rows}
          value={value}
          variant={variant}
          disabled={disabled}
          required={required}
          data-cy={dataCy}
          size={size}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    padding: theme.spacing(2),
  },
}));
