import { GridColumns } from '@mui/x-data-grid-pro';
import React from 'react';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { IAdditionalColumnModel } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnModel';
import { IAdditionalColumnSearch } from '../../../../../../service/additionalColumn/entity/IAdditionalColumnSearch';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';

export class AdditionalColumnEntityAdminUI
  extends DataTableUI<IAdditionalColumnModel, IAdditionalColumnSearch>
  implements IDataTableUI<IAdditionalColumnModel, IAdditionalColumnSearch> {
  setDomainId(): string {
    return 'additional/column/entity';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [

      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
      },
      {
        headerName: 'Тип',
        field: 'type',
        flex: 1,
      },
      {
        headerName: 'Порядок отображения',
        type: 'numeric',
        field: 'orderIndex',
        flex: 0.5,
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
        align: 'center',
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                style={{ marginRight: '8px' }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
