import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { DataTable } from '../../../../design/dataTable/DataTable';
import { IAdminPageProperties } from '../../IAdminPageProperties';
import { SpecificationCategoryAdminDomain } from './store/SpecificationCategoryAdminDomain';
import { useI18n } from '../../../../../application/language/useI18n';

export interface ISpecificationCategoryAdminTableProperties extends IAdminPageProperties { }

export const SpecificationCategoryAdminTable = observer(
  ({ layoutDomain }: ISpecificationCategoryAdminTableProperties) => {
    const { translate } = useI18n()

    useEffect(() => {
      layoutDomain.ui.pagePadding.setValue(0)
      return () => layoutDomain.ui.pagePadding.setValue(24)
    }, [])

    const [domain] = useState<SpecificationCategoryAdminDomain>(new SpecificationCategoryAdminDomain(layoutDomain));
    domain.ui.tableAddButtonName.setValue(translate('buttonsText.newCategory'))

    return <DataTable dataTableDomain={domain} tableHeight={window.innerHeight} />;
  },
);
