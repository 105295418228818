import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';
import { ISpecificationEntitySearch } from '../../../../../../service/specification/entity/ISpecificationeEntitySearch';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';
import { IStatusValueModel } from '../../../../../../service/status/value/IStatusValueModel';
import { IStatusValueSearch } from '../../../../../../service/status/value/IStatusValueSearch';
import { StatusValueAdminDomain } from './StatusValueAdminDomain';
import { StatusValueColor } from '../color/StatusValueColor';
import { StatusValueColorIcon } from '../color/StatusValueColorIcon';
import { Colors } from '../../../../../design/color/Colors';

export class StatusValueAdminUI
  extends DataTableUI<IStatusValueModel, IStatusValueSearch>
  implements IDataTableUI<IStatusValueModel, IStatusValueSearch> {
  constructor(
    public domain: StatusValueAdminDomain,
    rows: IDataTableRowsUI<IStatusValueModel, ISpecificationEntitySearch> | null,
  ) {
    super(domain, rows);
    this.rows.requestSearch = (searchTerm) => {
      this.requestSearch(searchTerm || '')
    }
  }
  setDomainId(): string {
    return 'settings/status/value';
  }

  requestSearch = (searchTerm: string) => {
    this.rows.searchTerm.setValue(searchTerm);
    const searchRegex = new RegExp(this.rows.escapeRegExp(searchTerm.trim()), 'i');
    const filteredRows = [...this.rows.entities.list]
      .filter((row) => {
        return Object.keys(row).some((field) => {
          let value = ''
          if (field === 'statusId') {
            value = this.domain.statusEntities.list?.filter((status) => row[field]?.includes(status.id || ''))
              .map((status) => status?.name).join().toLocaleLowerCase();
            return searchRegex.test(value);
          } else {
            value = this.rows.getFieldValueAsString(field, row[field]);
            return searchRegex.test(value);
          }
        });
      });
    this.rows.setFilteredEntities(filteredRows);
  }

  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Статус',
        field: 'statusId',
        flex: 1,

        valueGetter: (params) => {
          const statusName = this.domain.statusEntities.list.find(projectRole => projectRole.id === params?.value)?.name
          return statusName;
        },
        renderCell: (params: { value?: string }) => {
          return (<MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />)
        },
      },
      {
        headerName: 'Порядок отображения',
        type: 'string',
        field: 'orderIndex',
        flex: 0.5,
      },
      {
        headerName: 'Цвет',
        field: 'colorId',
        type: 'string',
        flex: 0.5,
        valueGetter: (params) => {
          const color = StatusValueColor.find((item) => item.id === params.row.colorId);
          return color?.name;
        },
        renderCell: (params) => {
          const color = StatusValueColor.find((item) => item.id === params.row.colorId);
          return (
            <StatusValueColorIcon
              color={Colors.rgbaOpacityTemplate(color?.rgbaTemplate, '0.6', 'rgba(255, 255, 255, 0.16)')}
              title={color?.name || 'Цвет не выбран'}
            />
          );
        },
      },
      {
        headerName: 'Показывать в системах',
        field: 'isShowInStats',
        type: 'boolean',
        flex: 0.5,
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
        align: 'center',
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                style={{ marginRight: '8px' }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
