import { injectRootService } from '../../../../../../../service/RootServiceFactory';
import { SpecificationCategoryType } from '../../../../../../../service/specification/category/ISpecificationCategoryModelConditionMap';
import { ISpecificationEntityModel } from '../../../../../../../service/specification/entity/ISpecificationEntityModel';
import { ISpecificationEntityService } from '../../../../../../../service/specification/entity/ISpecificationEntityService';
import { DataTableFormDomain } from '../../../../../../design/dataTable/parts/form/DataTableFormDomain';
import { IDataTableFormDomain } from '../../../../../../design/dataTable/parts/form/IDataTableFormDomain';
import { IDataTableDomain } from '../../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { SpecificationEntityModalUI } from './SpecificationEntityModalUI';

export class SpecificationEntityModalDomain
  extends DataTableFormDomain<ISpecificationEntityModel, ISpecificationEntityService>
  implements IDataTableFormDomain<ISpecificationEntityModel> {
  public ui: SpecificationEntityModalUI;
  constructor(
    entityService: ISpecificationEntityService,
    layoutDomain: IMainLayoutDomainStore,
    dataTableDomain: IDataTableDomain<ISpecificationEntityModel, any>,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(entityService, layoutDomain, dataTableDomain, new SpecificationEntityModalUI());
    this.ui = new SpecificationEntityModalUI();
  }

  async loadData(id: string | null) {
    await super.loadData(id);
    await this.loadDependency();
  }

  async loadDependency() {
    const categoriesSearchResult = await this.rootPrivateServices.specification.category.search({ limit: 10000000 });
    const categoriesSearchResultWithoutTextFields = categoriesSearchResult.data.filter((item) => item.categoryType === SpecificationCategoryType.specificationCategoryValue) || []
    this.ui.categories.setList(categoriesSearchResultWithoutTextFields);
  }
}
