import { GridColumns } from '@mui/x-data-grid/models';
import React from 'react';

import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IEntityListStore } from '../../../../../../common/store/interface/IEntityListStore';
import { ISpecificationCategoryModel } from '../../../../../../service/specification/category/ISpecificationCategoryModel';
import { ISpecificationEntitySearch } from '../../../../../../service/specification/entity/ISpecificationeEntitySearch';
import { ISpecificationEntityModel } from '../../../../../../service/specification/entity/ISpecificationEntityModel';
import { MultiLineText } from '../../../../../design/dataTable/parts/fieldArea/MultiLineText';
import { DataTableRowDependency } from '../../../../../design/dataTable/parts/rowDependency/DataTableRowDependency';
import { IDataTableRowsUI } from '../../../../../design/dataTable/parts/rows/IDataTableRowsUI';
import { DataTableUI } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IDataTableUI } from '../../../../../design/dataTable/store/IDataTableUI';
import { SpecificationEntityRowsUI } from './rows/SpecificationEntityRowsUI';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, Edit } from '@mui/icons-material';

export class SpecificationEntityAdminUI
  extends DataTableUI<ISpecificationEntityModel, ISpecificationEntitySearch>
  implements IDataTableUI<ISpecificationEntityModel, ISpecificationEntitySearch> {
  constructor(
    public domain: IDataTableDomain<ISpecificationEntityModel, ISpecificationEntitySearch>,
    rows: IDataTableRowsUI<ISpecificationEntityModel, ISpecificationEntitySearch> | null,
    public categories: IEntityListStore<ISpecificationCategoryModel> = injectEntityList<ISpecificationCategoryModel>(
      [],
    ),
  ) {
    super(domain, rows);
    this.rows = rows || new SpecificationEntityRowsUI(this);
  }
  setDomainId(): string {
    return 'settings/specification/entity';
  }
  async getColumns(): Promise<GridColumns> {
    this.setActionColumn()
    return [
      {
        headerName: 'Название',
        field: 'name',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Описание',
        field: 'description',
        flex: 1,
        renderCell: (params: { value?: string }) => {
          return <MultiLineText words={this.rows.searchTerm.value} value={params.value || ''} />;
        },
      },
      {
        headerName: 'Категория',
        type: 'numeric',
        field: 'categoryId',
        flex: 1,
        valueGetter: (params) => {
          const categoryName = this.domain.ui.rows.getFieldValueAsString('categoryId', params.value);
          return categoryName;
        },
        renderCell: (params: { value?: string; row: any }) => {
          const fieldValues = this.domain.ui.rows.getFieldValueAsDependencies('categoryId', params.row.categoryId);
          return (
            <DataTableRowDependency
              onClick={() => {
                // this.domain.onLinkAction(params.row.categoryId, '/settings/tag/category');
              }}
              getTextValue={(item: any) => item.name}
              searchTerm={this.rows.searchTerm.value}
              values={fieldValues}
            />
          );
        },
      },
      {
        headerName: 'Порядок отображения',
        type: 'string',
        field: 'orderIndex',
        flex: 0.5,
      },
      {
        headerName: 'Активен',
        field: 'isActive',
        type: 'boolean',
        flex: 0.3,
        align: 'center',
      },
    ];
  }

  async setActionColumn() {
    const actionColumn = this.actionColumnSettings.value
    actionColumn.actionItem = {
      field: 'action',
      headerName: 'Действия',
      flex: 0.3,
      minWidth: 60,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      align: 'center',
      renderCell: (row: any) => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            minWidth: 40,
            maxWidth: 60,
          }}>
            <Tooltip title="Редактировать">
              <IconButton
                disabled={!this.isCanEdit.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onEditRow(row)}
                style={{ marginRight: '8px' }}
              >
                <Edit sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Удалить">
              <IconButton
                disabled={!this.isCanDelete.value}
                // data-cy={`actions-button-for-${row.row.name}`} //todo data-cy
                onClick={(event) => this.domain.contextMenuDomain.onDeleteRow(row)}
              >
                <DeleteOutline sx={{ cursor: 'pointer', fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    }
    this.actionColumnSettings.setValue(actionColumn)
  }
}
